<template>
  <RouterLink
    v-slot="{ navigate, href }"
    class="misc-brand"
    el="a"
    :to="to"
    custom
  >
    <OwnType :text="label" :href="href" @click="onClick($event, navigate)" />
  </RouterLink>
</template>

<script lang="ts" setup>
import type { NavigationFailure, RouteLocationNamedRaw } from 'vue-router'

import { OwnType } from '@/ui'

const props = defineProps<{
  label: string
  to: RouteLocationNamedRaw
  onLinkClick?: (label: string) => void
}>()

const onClick = (
  event: MouseEvent,
  navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>
): void => {
  if (props.onLinkClick) {
    props.onLinkClick(props.label)
  }

  navigate(event)
}
</script>
