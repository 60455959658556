<template>
  <div class="flex-col">
    <div class="own-tabs__list flex-row flex-shrink-0">
      <button
        v-for="tab of tabs"
        :key="tab"
        :data-state="tab === activeTab ? 'active' : undefined"
        class="own-tabs__trigger flex-col gap-1 flex-1 pa-4 no-select"
        :value="tab"
        @click="activateTab(tab)"
      >
        <OwnType
          :text="triggers[tab]['title']"
          el="span"
          variant="paragraph-small"
          color="secondary"
        />

        <OwnProgressSpinner v-if="triggers[tab]['loading']" size="24" />
        <OwnType
          v-else
          class="text--truncate"
          :text="triggers[tab]['value']"
          el="span"
          variant="heading-small"
          :color="activeTab === tab ? 'primary' : 'secondary'"
        />
      </button>
    </div>

    <Component :is="content[activeTab]" />
  </div>
</template>

<script lang="ts" setup generic="T extends readonly string[]">
import { type Component, shallowRef } from 'vue'

import { OwnProgressSpinner } from '../OwnProgressSpinner'
import { OwnType } from '../OwnType'

import { OwnTabsTrigger } from './types'

const props = defineProps<{
  tabs: T
  triggers: Record<T[number], OwnTabsTrigger>
  content: Record<T[number], Component>
}>()

const emit = defineEmits<{ (event: 'selected', value: T[number]): void }>()

const activeTab = shallowRef<T[number]>(props.tabs[0] as T[number])

const activateTab = (newValue: T[number]) => {
  activeTab.value = newValue
  onModelValueChange(newValue)
}

const onModelValueChange = (newValue: T[number]) => {
  activeTab.value = newValue
  emit('selected', newValue)
}
</script>

<style lang="scss">
@use '@/styles/globals';

.own-tabs {
  &__list {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__trigger {
    min-width: 150px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--background-divider);

    &:hover {
      background-color: rgba(var(--raw-background-secondary), 0.5);
    }

    &[data-state='active'] {
      background-color: var(--background-secondary);
      border-bottom: 2px solid var(--misc-brand);
    }
  }
}
</style>
