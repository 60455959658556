<i18n locale="en">
{
  "empty-title": "Coupon"
}
</i18n>

<template>
  <PreviewSheet background="primary" radius="0" class="px-4 py-3">
    <PreviewSheet border="none" :placeholder="!coupon">
      <CouponPreviewRenderer
        v-if="coupon"
        :coupon="coupon"
        :is-loading="isLoading"
      />
      <PreviewSheet
        v-else
        class="pa-6 flex-col align-center justify-center h-full w-full"
      >
        <PreviewIcon color="secondary" size="24" :icon="PhPercent" />
        <PreviewTypography
          :text="t('empty-title')"
          color="secondary"
          variant="paragraphSmall"
        />
      </PreviewSheet>
    </PreviewSheet>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { PhPercent } from '@phosphor-icons/vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { ConfiguredClient } from '@/api'
import { Coupon } from '@/features/menu/types'
import { PreviewIcon, PreviewSheet, PreviewTypography } from '@/lib/builder'
import { logError } from '@/logger'
import { useMappedGetter } from '@/store'

import { CouponSection } from '../../../types'

import CouponPreviewRenderer from './CouponPreviewRenderer.vue'

const props = defineProps<{
  content: CouponSection
}>()

const { t } = useI18n()

const isLoading = ref<boolean>(true)
const code = ref<string>()
const coupon = ref<Coupon | null>(null)
const activeBrandId = useMappedGetter<string>('core/brand/active/id')

const couponCode = computed(() => {
  if (props.content.data?.type === 'code') {
    return props.content.data.params.code
  }
  return undefined
})

const couponId = computed(() => {
  if (props.content.data?.type === 'id') {
    return props.content.data.params.couponId
  }
  return undefined
})

const sectionType = computed(() => {
  return props.content?.data?.type
})

const getCouponByCode = async (couponCode: string) => {
  try {
    const [coupon] =
      await ConfiguredClient.menu.v1.brands.brand.coupons.getCouponsByCode({
        params: {
          brandId: activeBrandId.value,
          code: couponCode,
        },
      })

    return coupon
  } catch (error) {
    logError(error)
  }
}

const getCouponById = async (couponId: string) => {
  try {
    return await ConfiguredClient.coupons.coupon.get({
      params: { menuItemId: couponId },
      query: {},
    })
  } catch (error) {
    logError(error)
  }
}

const loadCoupon = async () => {
  if (!sectionType.value) return

  let loadedCoupon

  if (couponId.value) {
    loadedCoupon = await getCouponById(couponId.value)
  }

  if (couponCode.value) {
    loadedCoupon = await getCouponByCode(couponCode.value)
  }

  if (loadedCoupon) {
    coupon.value = loadedCoupon
  }
  isLoading.value = false
}

watch(couponId, loadCoupon)
watch(code, loadCoupon)

onMounted(async () => {
  isLoading.value = true
  await loadCoupon()
})
</script>
