<i18n locale="en">
{
  "actions": {
    "done": "Done"
  },
  "errors": {
    "load": "Failed to load job",
    "update": "Failed to update job"
  },
  "loading": "Getting job...",
  "options": "Options",
  "role": "Role",
  "success": "Successfully updated job",
  "title": "Edit Role"
}
</i18n>
<template>
  <OwnDialog :model-value="modelValue" @cancel="onClose">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="onClose" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <div v-if="!isLoading" class="flex-col gap-8">
          <OwnInputContainer :label="t('role')" :errors="title.status.errors">
            <OwnInput v-model="title.value" :placeholder="t('role')" />
          </OwnInputContainer>
          <div class="flex-col gap-2">
            <OwnType :text="t('options')" color="primary" variant="subtitle" />
            <SalaryInput v-model="salary" />
          </div>
        </div>
        <DefaultComponentLoader v-else :message="t('loading')" />
      </OwnDialogContent>
    </template>
    <template #footer>
      <OwnDialogFooter>
        <OwnButton
          :text="t('actions.done')"
          :disabled="isLoading"
          primary
          :processing="isSubmitting"
          @click="onSubmit"
        />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>
<script>
import { useI18n } from 'vue-i18n'

import { ConfiguredClient } from '@/api'
import DefaultComponentLoader from '@/components/loaders/DefaultComponentLoader.vue'
import { FormBuilder, Validators } from '@/forms'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnInput,
  OwnInputContainer,
  OwnType,
} from '@/ui'

import SalaryInput from '../components/SalaryInput.vue'
import {
  prepareSalaryForEditing,
  prepareSalaryForSubmission,
} from '../utils/salary.ts'

export default {
  name: 'EditJobDialog',
  components: {
    DefaultComponentLoader,
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnInput,
    OwnInputContainer,
    OwnType,
    SalaryInput,
  },
  mixins: [
    notify,
    FormBuilder({
      title: {
        debounce: 250,
        validateOnInit: true,
        validators: [Validators.required],
      },
    }),
  ],
  emits: ['update:modelValue', 'submit'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  props: {
    jobId: { type: String, required: true },

    /**
     * Show dialog, default to `true` so the dialog can be used by a router without any props
     */
    modelValue: { type: Boolean, default: true },

    /**
     * When `true`, navigate to the jobs page on close. Otherwise, emit `update:modelValue`.
     */
    navigate: { type: Boolean, default: true },
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      item: null,
      salary: {
        max: null,
        min: null,
      },
    }
  },
  async created() {
    this.isLoading = true
    await this.fetchJob()
    this.isLoading = false

    const { item } = this
    this.setInitialFormValues({
      title: item.title,
    })
    this.salary = prepareSalaryForEditing(item.salary)
  },
  methods: {
    async fetchJob() {
      const { jobId } = this

      try {
        this.item = await ConfiguredClient.careers.v1.jobs.job.getJob({
          params: { jobId },
        })
      } catch (err) {
        logError(err)
        return this.$notify(this.t('error'), 'error')
      }
    },
    onClose() {
      if (this.navigate) {
        this.$router.push({
          name: 'location.careers.jobs',
        })
      } else {
        this.$emit('update:modelValue', false)
      }
    },
    async onSubmit() {
      const { formValues, item, salary } = this

      this.isSubmitting = true

      try {
        this.item = await ConfiguredClient.careers.v1.jobs.job.updateJob({
          body: {
            ...formValues,
            salary: prepareSalaryForSubmission(salary),
          },
          params: {
            jobId: item.id,
          },
        })
        this.$notify(this.t('success'), 'success')
        this.onClose()
        this.$emit('submit', this.item)
      } catch (err) {
        logError(err)
        this.$notify(this.t('error'), 'error')
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>
