<template>
  <div
    class="own-input"
    :class="[
      isEmpty && 'own-input--empty',
      small && 'own-input--small',
      isFocused && 'own-input--focus',
      error && 'own-input--error',
      disabled && 'own-input--disabled',
      borderless && 'own-input--borderless',
    ]"
    @click="focusElement"
  >
    <div v-if="$slots.prepend" class="own-input__slot own-input__slot--prepend">
      <slot name="prepend" />
    </div>
    <span
      v-if="startText"
      class="own-input__text own-input__text--prepend text-paragraph"
      :class="[
        error && 'own-input__text--error',
        disabled && 'own-input__text--disabled',
      ]"
      v-text="startText"
    ></span>
    <input
      :id="accessibilityId"
      ref="inputField"
      v-model="inputValue"
      class="own-input__field"
      :class="[lookupFont(font), error && 'own-input__field--error']"
      name="native-input"
      :type="type"
      :step="step"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      :disabled="disabled"
      :aria-describedby="error ? `${accessibilityId}-message` : undefined"
      @click.stop
      @focus="onFocus"
      @blur="onBlur"
      v-on="inputListeners"
    />
    <span
      v-if="endText"
      class="own-input__text own-input__text--append text-paragraph"
      :class="[
        error && 'own-input__text--error',
        disabled && 'own-input__text--disabled',
      ]"
      v-text="endText"
    ></span>
    <div v-if="$slots.append" class="own-input__slot own-input__slot--append">
      <slot name="append" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, useAttrs } from 'vue'

import { FontType, lookupFont } from '../Common/fonts'

withDefaults(
  defineProps<{
    accessibilityId?: string
    autocapitalize?: HTMLInputElement['autocapitalize']
    autocomplete?: string
    borderless?: boolean
    disabled?: boolean
    endText?: string
    error?: boolean
    font?: FontType
    max?: string
    min?: string
    placeholder?: string
    small?: boolean
    startText?: string
    step?: number
    type?: 'text' | 'password' | 'email' | 'number' | 'date'
  }>(),
  {
    accessibilityId: undefined,
    autocapitalize: 'off',
    autocomplete: 'off',
    borderless: false,
    disabled: false,
    endText: undefined,
    error: false,
    font: 'paragraph',
    max: undefined,
    min: undefined,
    placeholder: undefined,
    small: false,
    startText: undefined,
    step: undefined,
    type: 'text',
  }
)

const attrs = useAttrs()
const inputListeners = computed(() => {
  const listeners: Record<string, Function> = {}
  for (const key in attrs) {
    if (key.startsWith('on') && typeof attrs[key] === 'function') {
      listeners[key] = attrs[key]
    }
  }
  return listeners
})

const inputField = ref<HTMLInputElement | null>(null)

const isFocused = ref(false)

const inputValue = defineModel<number | string | undefined>({
  default: undefined,
})

const isEmpty = computed(
  () =>
    inputValue.value === null ||
    inputValue.value === undefined ||
    inputValue.value === ''
)

const focusElement = () => {
  if (inputField.value) {
    inputField.value.focus()
  }
}

const onBlur = () => {
  isFocused.value = false
}

const onFocus = () => {
  isFocused.value = true
}

defineExpose({
  focusElement,
})
</script>

<style lang="scss">
@use '@/styles/globals';

.own-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--background-primary);

  border-radius: 8px;
  border: 1px solid var(--background-divider);
  caret-color: var(--misc-brand);

  cursor: text;

  @include globals.control-shadow();

  transition:
    background-color 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    border 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    color 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    box-shadow 0.2s cubic-bezier(0.68, -0.04, 0.26, 0.87);

  &--search {
    padding: 8px 12px;
  }

  &--small {
    padding: 8px;
  }

  &--rounded {
    border-radius: 30px;
  }

  &--focus#{&}--borderless {
    box-shadow: none;
  }

  &--clamp {
    align-self: flex-start;
  }

  &:not(#{&}--disabled, #{&}--error, #{&}--focus, #{&}--borderless) {
    &:hover {
      background: var(--background-highlight);
      border-color: rgba(var(--raw-misc-brand), 0.05);
    }
  }

  &--disabled {
    cursor: not-allowed;
    background-color: var(--background-secondary);
    border-color: var(--background-divider);
  }

  &--error {
    color: var(--status-danger);
    border-color: var(--status-danger);
  }

  &--focus {
    background: var(--background-highlight);
    border-color: rgba(var(--raw-misc-brand), 0.05);
  }

  /**
     TODO: Tucker - this started as borderless but is really a use case
     where the component is `embedded` for another purpose.
     Update this to `embedded` for more context

     https://linear.app/owner/issue/OWN-6067/update-input-borderless-to-embedded
   */
  &--borderless {
    border: none;
    padding: 0;
    border-radius: 0;
    background: transparent;
    @include globals.no-shadow();
  }

  &:not(#{&}--empty) {
    // Sohne font doesn't have spacing between password dots
    input[type='password'] {
      letter-spacing: 0.2em;
    }
  }

  &__field {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 20px;
    min-width: 20px;
    width: 100%;

    color: var(--text-color-primary);

    &::placeholder {
      color: var(--text-color-placeholder);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--text-color-placeholder);
      border-color: var(--background-divider);
      opacity: 0.2;
    }

    &--error {
      color: var(--status-danger);

      &::placeholder {
        color: var(--status-danger);
      }
    }

    // We are handling the focus ring at the component level, not the input level
    &:focus {
      outline: none;
    }
  }

  &__text {
    color: var(--text-color-placeholder);
    user-select: none;

    &--append {
      margin-left: 8px;
    }

    &--error {
      color: var(--status-danger);
    }

    &--disabled {
      color: var(--background-divider);
    }
  }

  &__icon,
  &__slot {
    color: var(--text-color-placeholder);

    &--prepend {
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--append {
      margin-left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--error {
      color: var(--status-danger);
    }

    &--disabled {
      color: var(--background-divider);
    }
  }
}
</style>
