<template>
  <div class="flex-row gap-4 align-start w-full">
    <div class="flex-col gap-1 flex-1">
      <OwnType v-if="title" :text="title" variant="subtitle" color="primary" />
      <slot name="body">
        <OwnType v-if="body" :text="body" variant="paragraph-small" />
      </slot>
    </div>

    <Component :is="icon" :class="[iconColor, 'flex-shrink-0']" size="24" />
  </div>
</template>
<script setup lang="ts">
import { PhInfo, PhWarning } from '@phosphor-icons/vue'
import { type Component, computed } from 'vue'

import { OwnType } from '../OwnType'

const props = withDefaults(
  defineProps<{
    body?: string
    title?: string
    type?: 'warning' | 'danger' | 'brand' | 'neutral' | 'success'
    icon?: Component
  }>(),
  {
    body: undefined,
    icon: undefined,
    title: undefined,
    type: 'brand',
  }
)

const icon = computed<Component>(() => {
  if (props.icon) return props.icon

  if (props.type === 'danger' || props.type === 'warning') {
    return PhWarning
  }

  return PhInfo
})

const iconColor = computed(() => {
  switch (props.type) {
    case 'danger':
      return 'status-danger'
    case 'warning':
      return 'status-warning'
    case 'success':
      return 'status-success'
    case 'neutral':
      return 'text-color-placeholder'
    default:
      return 'misc-brand'
  }
})
</script>
