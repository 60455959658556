<template>
  <PreviewSheet radius="0" background="primary" class="px-4 py-3">
    <div class="gift-card-preview">
      <div
        v-for="(row, idxRow) of rows"
        :key="idxRow"
        class="flex-row"
        :class="[`gift-card-preview__row-${idxRow % 2 === 0 ? 'odd' : 'even'}`]"
        spacing="4"
        row
      >
        <div
          v-for="(item, idxItem) of row"
          :key="`${idxRow}-${idxItem}`"
          class="gift-card-preview__image-wrapper"
        >
          <component :is="item" class="gift-card-preview__image" />
        </div>
      </div>
    </div>
  </PreviewSheet>
</template>

<script>
import GenericGiftCard from '@/components/gift-card-images/GenericGiftCard.vue'
import HappyBirthday from '@/components/gift-card-images/HappyBirthday.vue'
import HappyHolidays from '@/components/gift-card-images/HappyHolidays.vue'
import Thanks from '@/components/gift-card-images/Thanks.vue'
import { PreviewSheet } from '@/lib/builder'

const oddRow = [HappyBirthday, Thanks, HappyHolidays, GenericGiftCard]
const evenRow = [
  HappyHolidays,
  GenericGiftCard,
  HappyBirthday,
  Thanks,
  HappyHolidays,
]

export default {
  name: 'GiftCardPreview',
  components: {
    PreviewSheet,
  },
  data() {
    return {
      rows: [oddRow, evenRow, oddRow],
    }
  },
}
</script>

<style lang="scss" scoped>
.gift-card-preview {
  &__row-odd {
    width: 600px;
    position: relative;
    left: -20px;
    overflow: hidden;
  }

  &__row-even {
    width: 760px;
    position: relative;
    left: -100px;
    overflow: hidden;
  }

  &__image-wrapper {
    width: 175px;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
  }

  &__image {
    border-radius: 8px;
  }
}
</style>
