import { createStore } from 'vuex'

import modules from './modules'
import plugins from './plugins'

export default createStore({
  // @ts-expect-error Tech debt: the modules do not match the Vuex types
  modules,
  plugins,
  strict: false,
})

export * from './utils/useMappedGetter'
export * from './utils/useMappedAction'
