<template>
  <OwnCard
    class="own-toggle"
    :background-color="control ? 'primary' : 'transparent'"
    :border-color="control ? 'divider' : 'transparent'"
    :control="control"
    el="button"
    @keyup.enter="toggleActive"
    @click.stop="toggleActive"
  >
    <span
      :class="[
        'flex-row gap-2 align-center justify-between w-full',
        control && 'own-toggle__trigger-control',
      ]"
    >
      <span class="flex-row gap-2 justify-start align-start">
        <slot name="prepend" />
        <span v-if="label || description" class="flex-col gap-1">
          <OwnType
            v-if="label"
            :text="label"
            class="own-toggle__text"
            el="label"
            variant="subtitle"
            color="primary"
          />
          <OwnType
            v-if="description"
            :text="description"
            class="own-toggle__text"
            el="span"
            variant="paragraph-small"
          />
        </span>
      </span>

      <ToggleSwitch class="flex-shrink-0" :active="inputValue" />
    </span>
  </OwnCard>
</template>

<script lang="ts" setup>
import { OwnCard } from '../OwnCard'
import { OwnType } from '../OwnType'

import ToggleSwitch from './components/ToggleSwitch.vue'

withDefaults(
  defineProps<{
    control?: boolean
    description?: string
    label?: string
  }>(),
  {
    control: false,
    description: undefined,
    label: undefined,
  }
)

const inputValue = defineModel<boolean>({ default: false })

const toggleActive = () => {
  inputValue.value = !inputValue.value
}
</script>

<style lang="scss">
.own-toggle {
  width: 100%;

  &__text {
    cursor: pointer;
  }

  &__trigger-control {
    padding: 12px 16px;
  }
}
</style>
