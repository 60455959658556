<template>
  <Component
    :is="el"
    :class="[
      'own-status w-full flex-row align-center',
      isTrigger ? 'justify-between' : 'justify-start',
      `own-status--${status}`,
      isTrigger && 'own-status--trigger',
    ]"
  >
    <span class="text-button-large" v-text="text"></span>

    <PhCaretDown v-if="isTrigger" size="16" />
    <Component :is="icon" v-else-if="icon" size="20" />
  </Component>
</template>

<script>
import { PhCaretDown } from '@phosphor-icons/vue'

export default {
  name: 'OwnStatus',
  components: { PhCaretDown },
  props: {
    /** Use this to render a specific component */
    el: { type: String, default: 'div' },

    /** An optional icon for the badge */
    icon: { type: Object, default: undefined },

    /** Is a trigger for an action menu */
    isTrigger: {
      type: Boolean,
      default: false,
    },

    /** The status for the badge */
    status: {
      type: String,
      validate: (v) => ['success', 'error', 'warning', 'neutral'].includes(v),
      default: 'neutral',
    },

    /** The text for the badge */
    text: { type: String, required: true },
  },
}
</script>

<style lang="scss">
@use '@/styles/globals';

@mixin define-color($color) {
  background: linear-gradient(
      0deg,
      rgba($color, 0.2) 0%,
      rgba($color, 0.2) 100%
    ),
    #fff;
  color: rgb($color);

  &:hover.own-status--trigger {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%
      ),
      linear-gradient(0deg, rgba($color, 0.2) 0%, rgba($color, 0.2) 100%), #fff;
    color: rgba($color, 0.5);
  }
}

.own-status {
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  @include globals.control-shadow();

  &--neutral {
    @include define-color(var(--raw-text-color-secondary));
  }

  &--error {
    @include define-color(var(--raw-status-danger));
  }

  &--success {
    @include define-color(var(--raw-status-success));
  }

  &--warning {
    @include define-color(var(--raw-status-warning));
  }
}
</style>
