import { type FormatFn } from './Format.types'

/**
 * Formats a numeric value to the ordinal value, frequently used in showing comparative rankings (1 to 1st, 3 to 3rd)
 *
 * @example const value = RankFormatter(1) // value === '1st'
 * @param value Value to format (eg 1 or 15)
 * @returns A formatted ordinal string.
 */
export const RankFormatter: FormatFn<number> = (value: number): string => {
  const ordinalRules = new Intl.PluralRules(undefined, { type: 'ordinal' })

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ])

  const rule = ordinalRules.select(value)
  const suffix = suffixes.get(rule)
  return `${value}${suffix ?? ''}`
}
