<template>
  <div :class="['flex-col w-full', variant === 'large' ? 'gap-2' : 'gap-1']">
    <OwnType
      v-if="title"
      :text="title"
      :variant="titleVariant"
      :color="titleColor"
      :align="align"
    />
    <OwnType
      v-if="subtitle"
      :text="subtitle"
      :color="subtitleColor"
      :align="align"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { OwnType } from '@/ui/OwnType'

const props = withDefaults(
  defineProps<{
    align?: 'left' | 'center' | 'right'
    subtitle?: string
    subtitleColor?: 'primary' | 'secondary'
    title?: string
    titleColor?: 'primary' | 'secondary'
    variant?: 'large' | 'medium' | 'small'
  }>(),
  {
    align: 'left',
    subtitle: undefined,
    subtitleColor: 'secondary',
    title: undefined,
    titleColor: 'primary',
    variant: 'small',
  }
)

const titleVariant = computed(() => {
  switch (props.variant) {
    case 'large':
      return 'heading'

    case 'medium':
      return 'title'

    default:
      return 'subtitle'
  }
})
</script>
