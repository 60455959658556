<template>
  <div class="default-page-layout flex-col gap-10">
    <div class="flex-col gap-8">
      <PageHeader
        v-if="computedTitle || computedSubtitle || $slots['actions']"
        :title="computedTitle"
        :subtitle="computedSubtitle"
      >
        <template v-if="subtitle || $slots['subtitle']" #subtitle>
          <slot name="subtitle" />
        </template>

        <template v-if="$slots['actions']" #actions>
          <slot name="actions" />
        </template>
      </PageHeader>

      <div v-if="$slots['filter-bar']" class="flex-col gap-10">
        <div class="flex-row gap-3 align-center justify-start">
          <slot name="filter-bar" />
        </div>
      </div>
    </div>

    <CollectionsBanner />

    <slot />
  </div>

  <slot name="dialog" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { CollectionsBanner, usePageDescription, usePageTitle } from '@/core'
import { useBreakpoints } from '@/utils'

import PageHeader from './PageHeader.vue'

const props = withDefaults(
  defineProps<{
    maxWidth?: number
    subtitle?: string
    title?: string
  }>(),
  {
    maxWidth: 1440,
    subtitle: undefined,
    title: undefined,
  }
)

const { isDesktop } = useBreakpoints()

const maxWidth = computed(() => `${props.maxWidth + 80}px`)

const pageTitle = usePageTitle()
const pageDescription = usePageDescription()

const computedTitle = computed(() => {
  if (props.title) return props.title

  if (!isDesktop.value) return undefined
  return pageTitle.value
})

const computedSubtitle = computed(() => {
  if (props.subtitle) return props.subtitle

  return pageDescription.value
})
</script>

<style lang="scss" scoped>
@use '@/styles/breakpoints';

.default-page-layout {
  background-color: var(--background-primary);
  max-width: v-bind(maxWidth);
  min-height: 100%;
  padding: 40px 16px;

  @include breakpoints.respond-to('md-and-up') {
    margin: 0 auto;
    padding: 40px 56px;
  }
}
</style>
