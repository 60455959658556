<i18n locale="en">
  {
    "cta": "Use Coupon",
    "title": "Get {amount} off your order",
    "empty-title": "Coupon",
    "subtitles": {
      "coupon": {
        "prefix": "Use code {code} ",
        "order": "on your next online order.",
        "order-items": "on select items in your next online order.",
        "order-timeframe": "on your next online order. Valid through {validThroughDate}.",
        "order-items-timeframe": "on select items in your next online order. Valid through {validThroughDate}.",
        "min": "on your next online order of {min} or more.",
        "min-items": "on select items in your next online order of {min} or more.",
        "min-timeframe": "on your next online order of {min} or more. Valid through {validThroughDate}.",
        "min-items-timeframe": "on select items in your next online order of {min} or more. Valid through {validThroughDate}."
      }
    }
  }
  </i18n>

<template>
  <PreviewSheet
    border="none"
    background="secondary"
    class="coupon-preview-renderer__container"
    :placeholder="!coupon"
  >
    <div v-if="isLoading" class="flex-row align-center justify-center">
      <OwnProgressSpinner size="24" />
    </div>
    <div v-else class="flex-row h-full w-full">
      <PreviewSheet
        v-if="coupon"
        background="primary"
        class="flex-row h-full w-full"
      >
        <div class="coupon-preview-renderer__icon_container">
          <PreviewIcon
            color="secondary"
            size="24"
            :icon="PhGift"
            class="coupon-preview-renderer__icon"
          />
        </div>
        <div class="flex-1 flex-col justify-between px-6 py-6">
          <PreviewTypography
            :text="
              t('title', {
                amount: couponAmount,
                code: coupon.code.toUpperCase(),
              })
            "
            color="brand"
            variant="titleSmall"
            class="coupon-preview-renderer__title"
          />
          <PreviewTypography
            :text="couponDescription"
            variant="paragraphSmall"
          />
        </div>
      </PreviewSheet>
      <div
        v-else
        background="secondary"
        class="pa-6 flex-col align-center justify-center h-full w-full"
      >
        <PreviewIcon
          color="secondary"
          size="24"
          :icon="PhGift"
          class="coupon-preview-renderer__icon"
        />
        <PreviewTypography
          :text="t('empty-title')"
          color="secondary"
          variant="paragraphSmall"
        />
      </div>
    </div>
  </PreviewSheet>
</template>
<script lang="ts" setup>
import { PhGift } from '@phosphor-icons/vue'
import { format, parseISO } from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { Coupon } from '@/features/menu/types'
import { getCurrencyFormatter, PercentFormatter } from '@/format'
import { PreviewIcon, PreviewTypography } from '@/lib/builder'
import { OwnProgressSpinner } from '@/ui'

const props = defineProps<{
  isLoading: boolean
  coupon: Coupon
}>()

const { t } = useI18n()

const currencyFormatter = getCurrencyFormatter({ cents: 'show-non-zero' })

const couponAmount = computed(() => {
  if (
    props.coupon.type === 'item-percent' ||
    props.coupon.type === 'ticket-percent'
  ) {
    return PercentFormatter(props.coupon.amount)
  }
  return currencyFormatter(props.coupon.amount)
})

const couponDescription = computed(() => {
  const couponType = ['item-fixed', 'item-percent'].includes(props.coupon.type)
    ? 'item'
    : 'ticket'

  const min = props.coupon.min ? currencyFormatter(props.coupon.min) : null
  const validThroughDate = props.coupon.end
    ? format(parseISO(props.coupon.end), 'MM/dd/yy')
    : null

  const slugChunks = []

  if (min) {
    slugChunks.push('min')
  } else {
    slugChunks.push('order')
  }

  if (couponType === 'item') {
    slugChunks.push('items')
  }

  if (validThroughDate) {
    slugChunks.push('timeframe')
  }

  return (
    t('subtitles.coupon.prefix', {
      code: props.coupon.code.toUpperCase(),
    }) +
    t(`subtitles.coupon.${slugChunks.join('-')}`, {
      min,
      validThroughDate,
    })
  )
})
</script>

<style lang="scss" scoped>
.coupon-preview-renderer {
  &__container {
    height: 124px;
  }

  &__icon_container {
    height: 100px;
    width: 100px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    border: 1px dashed var(--background-divider);
  }

  &__icon {
    margin: auto;
  }

  &__title {
    text-decoration: underline;
  }
}
</style>
