<template>
  <span
    class="toggle-switch"
    :class="[props.active && 'toggle-switch--active']"
    role="checkbox"
  >
    <span
      class="toggle-switch__slider"
      :class="[props.active && 'toggle-switch__slider--active']"
    >
      <PhCheck v-if="props.active" size="12" />
      <PhX v-else size="12" />
    </span>
  </span>
</template>

<script lang="ts" setup>
import { PhCheck, PhX } from '@phosphor-icons/vue'

const props = defineProps<{
  active: boolean
}>()
</script>

<style lang="scss" scoped>
@use 'sass:color';

@use '@/styles/globals';
@use '../../Common/scss/focus';

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid var(--background-divider);
  background-color: var(--background-secondary);

  @include globals.control-shadow();

  &--active {
    background-color: var(--misc-brand);
    border-color: var(--misc-brand);
  }

  &__slider {
    position: absolute;
    top: -1px;
    left: -1px;
    cursor: pointer;
    background-color: var(--background-primary);
    height: 24px;
    width: 24px;
    border-radius: 12px;
    border: 1px solid var(--background-divider);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--background-divider);

    transition: transform 72ms ease-in-out;

    @include globals.control-shadow();

    &--active {
      // Technically this should be 18px, but with the dark bg
      // 18 creates clipping artifacts
      transform: translateX(19px);
      color: var(--misc-brand);
    }
  }
}
</style>
