import { format, isToday, isTomorrow, isYesterday, parseISO } from 'date-fns'

import i18nPlugin from '@/plugins/i18n'

import { type FormatFn } from './Format.types'
const i18n = i18nPlugin.global

const DATE_FORMAT = 'MMM dd, yyyy'

export const DateFormatter: FormatFn<string> = (value) => {
  const parsedDate = parseISO(value)
  return format(parsedDate, DATE_FORMAT)
}

export const DateFormatterWithDailyContext: FormatFn<string> = (value) => {
  const parsedDate = parseISO(value)

  if (isToday(parsedDate)) {
    return i18n.t('formatters.date.today')
  }

  if (isTomorrow(parsedDate)) {
    return i18n.t('formatters.date.tomorrow')
  }

  if (isYesterday(parsedDate)) {
    return i18n.t('formatters.date.yesterday')
  }

  return format(parsedDate, DATE_FORMAT)
}

export interface DateFormatterOptions {
  /**
   * display - Default display value
   * display-context - Falls back to default context but includes `Today, Yesterday, and Tomorrow`
   */
  variant: 'display' | 'display-context'
}

export const configureDateFormatter = (
  options: DateFormatterOptions = {
    variant: 'display',
  }
): FormatFn<string> => {
  switch (options.variant) {
    case 'display-context':
      return DateFormatterWithDailyContext
    default:
      return DateFormatter
  }
}
